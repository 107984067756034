import React, { useState } from "react"

import {
  RowSpaceBetween,
  RowStart,
  RowEnd,
  RowCenter,
} from "../../components/general_styled"
import {
  NavbarStyled,
  BodyNavbar,

  LinkWrapper,

  BodyLine,

  RouterLinkStyledText,
  RouterBodyLinkStyledText,
  ConnectButtonWrapperStyled,

} from "./styled"
import ConnectWallet from '../../shared/ConnectWallet';



import Logo from "../../assets/images/mobile/logo.png"
import Hamburger from "../../assets/images/mobile/hamburger.png"
import Close from "../../assets/images/mobile/close.png"
import useWindowDimensions from "../../utils/windowDimensions"





export default function MintNavbar(props) {
  const { width } = useWindowDimensions()
  const [isOpen, setIsOpen] = useState(false)



  return width < 1195 ? (
    <>
      <NavbarStyled className="mobile">
        <RowStart>
          <img src={Logo} alt="logo" className="ml-30px" height={25} />
        </RowStart>
        <RowEnd>
          <img
            src={isOpen ? Close : Hamburger}
            alt="hamburger"
            className={"mr-30px " + (isOpen && "rotate")}
            height={25}
            onClick={() => setIsOpen(!isOpen)}
          />
        </RowEnd>
      </NavbarStyled>

      <BodyNavbar className={isOpen && "open"}>
        {isOpen && (
          <>
            <LinkWrapper>
              <RouterBodyLinkStyledText className='hover-2' to="/">
                Home
              </RouterBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine/>
            <LinkWrapper>
              <RouterBodyLinkStyledText className='hover-2' to="/verify">
                Verify
              </RouterBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine/>
            <LinkWrapper>
              <RouterBodyLinkStyledText className='hover-2' to="/whitelisted">
                WL Mint
              </RouterBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine/>
            <LinkWrapper>
              <RouterBodyLinkStyledText className='hover-2' to="/mint">
                Dutch Auction
              </RouterBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine/>
            <br/>
          <RowCenter>
          <ConnectButtonWrapperStyled className='hover-social'>
            <ConnectWallet/>
          </ConnectButtonWrapperStyled>
          </RowCenter>
        </>
          
        )}
      </BodyNavbar>
    </>
  ) : (
    <NavbarStyled>

      <RowSpaceBetween>
        <RowStart>
          <LinkWrapper>
            <RouterLinkStyledText className='hover-2' to="/">
              Home
            </RouterLinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <RouterLinkStyledText className='hover-2' to="/verify">
              Verify
            </RouterLinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <RouterLinkStyledText className='hover-2' to="/whitelisted">
              WL Mint
            </RouterLinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <RouterLinkStyledText className='hover-2' to="/mint">
              Dutch Auction
            </RouterLinkStyledText>
          </LinkWrapper>
        </RowStart>
        <RowEnd>
          <ConnectButtonWrapperStyled className='hover-social'>
            <ConnectWallet/>
          </ConnectButtonWrapperStyled>

          {/* <ConnectButtonWrapperStyled className='hover-social' onClick={whitelistMintHandler}><ConnectButtonText>{"CONNECT"}</ConnectButtonText></ConnectButtonWrapperStyled> */}

        </RowEnd>
      </RowSpaceBetween>
    </NavbarStyled>
  )
}
