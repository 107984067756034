import { RenderComponentProps } from 'masonic'
import { FC } from 'react'
import { ImageData } from '..'
import { CloseIcon } from './styled-components/Icon'
import HoverImage from '../../../assets/images/desktop/hover.png'
import { CloseBtn, ImageBody, ImageBox, HoverBox, CenterBottom, ProfileContainer, DescriptionContainer, DoubleImageContainer, ImageContainer, ImageTitle, TraitContainer, TraitRow, Trait, ViewerFrame, ProfileImageBox, TraitText } from './styled-components/StyledComponents'
import { useViewer } from "./useViewer"
import ViewerBorder from "../../../assets/images/desktop/viewer_border.png";
import ViewerBorderMobile from "../../../assets/images/mobile/box_team.png";
import useWindowDimensions from "../../../utils/windowDimensions"
import { OpenseaIconStyled, SocialWrapperStyled } from '../../../layouts/Navbar/styled'
import OpenseaLogo from "../../../assets/images/desktop/opensea_icon_black.svg"


interface ImageViewerProps {
  element: RenderComponentProps<ImageData>;
}

export const ImageViewer: FC<ImageViewerProps> = (props) => {

  const { dontClose, closeViewer, show, showViewer, hover, setHover } = useViewer()
  const { width } = useWindowDimensions()

  let tripleTraitRows = [];
  for (let idx = 0; idx < props.element.data.attributes.length; idx += 3) {
    let row = [];
    if (props.element.data.attributes[idx]) {
      row.push(
        <Trait className={width > 650 ? 'desktop' : 'mobile' }>
          <TraitText className={width > 650 ? 'desktop' : 'mobile' } style={{ fontFamily: "Akira", fontWeight: "bold" }}>{props.element.data.attributes[idx].trait_type}</TraitText>
          <TraitText className={width > 650 ? 'desktop' : 'mobile' } style={{ fontFamily: "Montserrat" }}>{props.element.data.attributes[idx].value}</TraitText>
        </Trait>
      )
    }
    if (props.element.data.attributes[idx + 1]) {
      row.push(
        <Trait className={width > 650 ? 'desktop' : 'mobile' }>
          <TraitText className={width > 650 ? 'desktop' : 'mobile' } style={{ fontFamily: "Akira", fontWeight: "bold", marginLeft: "1em", marginRight: "1em" }}>{props.element.data.attributes[idx + 1].trait_type}</TraitText>
          <TraitText className={width > 650 ? 'desktop' : 'mobile' } style={{ fontFamily: "Montserrat", marginLeft: "1em", marginRight: "1em" }}>{props.element.data.attributes[idx + 1].value}</TraitText>
        </Trait>
      )
    }
    if (props.element.data.attributes[idx + 2]) {
      row.push(
        <Trait className={width > 650 ? 'desktop' : 'mobile' }>
          <TraitText className={width > 650 ? 'desktop' : 'mobile' } style={{ fontFamily: "Akira", fontWeight: "bold", marginLeft: "1em" }}>{props.element.data.attributes[idx + 2].trait_type}</TraitText>
          <TraitText className={width > 650 ? 'desktop' : 'mobile' } style={{ fontFamily: "Montserrat", marginLeft: "1em" }}>{props.element.data.attributes[idx + 2].value}</TraitText>
        </Trait>
      )
    }
    tripleTraitRows.push(row)
  }

  return (
    <>
      {
        (
          <ImageBody className={width > 415 ? 'desktop' : 'mobile' } onClick={showViewer} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div>
                <ImageBox src={"https://kiwami-gallery.s3.us-east-2.amazonaws.com/t/"+ props.element.data.image} alt="nft" />
                </div>
            {hover && (width > 415) &&
              <div>
                <HoverBox className={width > 415 ? 'desktop' : 'mobile' } src={HoverImage} />
                <DescriptionContainer className='kiwamiNum' style={{ color: 'white' }}>
                  <CenterBottom>{props.element.data.name}</CenterBottom>
                </DescriptionContainer>
              </div>
            }
          </ImageBody>
        )
      }

      {
        show && (
          <ViewerFrame onClick={closeViewer}>

            <CloseBtn onClick={closeViewer}>
              <CloseIcon />
            </CloseBtn>
            {
              width > 650 ?
              <ProfileContainer onClick={dontClose} style={{ backgroundImage: `url(${ViewerBorder})` }}>
                <ImageContainer>
                  <DoubleImageContainer>
                    <ProfileImageBox src={"https://kiwami-gallery.s3.us-east-2.amazonaws.com/"+ props.element.data.image} alt="nft" />
                  </DoubleImageContainer>
                  <ImageTitle>
                    {props.element.data.name}
                  </ImageTitle>
                  <SocialWrapperStyled className='hover-social profile' onClick={() => {
                    setTimeout(() => { 
                      window.open(`https://opensea.io/assets/0x701a038af4bd0fc9b69a829ddcb2f61185a49568/${props.element.data.name.substring(8)}`)
                    }, 500);
                    }}>
                    <OpenseaIconStyled src={OpenseaLogo}/>
                  </SocialWrapperStyled>
                  {/* <ImageDescription>{props.element.data.description}</ImageDescription> */}
                </ImageContainer>
                <TraitContainer>
                  {
                    tripleTraitRows.map((tripleTraits: any) => {
                      return (<TraitRow>{tripleTraits}</TraitRow>)
                    })
                  }
                </TraitContainer>
                {/* <OpenseaContainer>
                  <SocialWrapperStyled className='hover-social profile' onClick={() => {
                    setTimeout(() => { 
                      window.open("https://opensea.io/collection/kiwami-genesis")
                    }, 500);
                    }}>
                    <OpenseaIconStyled src={OpenseaLogo}/>
                  </SocialWrapperStyled>
                </OpenseaContainer> */}
                {/* <div style={{ color: 'white' }}>Link:  <a href={props.element.data.external_url}>{props.element.data.external_url}</a> </div>
                  <div style={{ color: 'white' }}>
                    {
                      (props.element.data.attributes.map((attribute: Attribute) => {
                        return (<div> {attribute.trait_type + ": " + attribute.value} </div>)
                      }
                      ))
                    }
                  </div> */}
              </ProfileContainer> :
              <ProfileContainer className='mobile' onClick={dontClose} style={{ backgroundImage: `url(${ViewerBorderMobile})` }}>
                <ImageContainer className='mobile'>
                  <DoubleImageContainer>
                    <ProfileImageBox src={"https://kiwami-gallery.s3.us-east-2.amazonaws.com/"+ props.element.data.image} alt="nft" />
                  </DoubleImageContainer>
                  <ImageTitle>
                    {props.element.data.name}
                  </ImageTitle>
                  <SocialWrapperStyled className='hover-social profile' onClick={() => {
                    setTimeout(() => { 
                      window.open(`https://opensea.io/assets/0x701a038af4bd0fc9b69a829ddcb2f61185a49568/${props.element.data.name.substring(8)}`)
                    }, 500);
                    }}>
                    <OpenseaIconStyled src={OpenseaLogo}/>
                  </SocialWrapperStyled>
                  {/* <ImageDescription>{props.element.data.description}</ImageDescription> */}
                </ImageContainer>
                <TraitContainer className='mobile'>
                  {
                    tripleTraitRows.map((tripleTraits: any) => {
                      return (<TraitRow >{tripleTraits}</TraitRow>)
                    })
                  }
                </TraitContainer>
                {/* <div style={{ color: 'white' }}>Link:  <a href={props.element.data.external_url}>{props.element.data.external_url}</a> </div>
                  <div style={{ color: 'white' }}>
                    {
                      (props.element.data.attributes.map((attribute: Attribute) => {
                        return (<div> {attribute.trait_type + ": " + attribute.value} </div>)
                      }
                      ))
                    }
                  </div> */}
              </ProfileContainer>
          }




          </ViewerFrame>
        )
      }
    </>
  )
}