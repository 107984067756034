import { useWeb3React } from '@web3-react/core';
import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import useInterval from 'use-interval';

import BgTeam from "../../assets/images/desktop/bg_team.jpg"
import MintProfile from "../../assets/images/desktop/mint.png"
import { ColStart, ColCenter } from "../../components/general_styled"
import MintNavbar from "../../layouts/MintNavbar"
import { BackgroundStyled } from '../Home/styled';
import { HeaderStyled, IsWhitelistButtonText, DutchStatsContainer, DutchPriceDetails, DutchMintButtonContainer, DutchInputQuantity, DutchSubmitButtonWrapper, DutchReclaimContainer, DutchReclaimSubContainer, ReclaimStat, ReclaimButtonWrapper, MintContentStyled, DutchReclaimPlaceholderContainer, BodyLine, Profile, DAContainer, DAContent } from './styled';
// import { ContentStyled, HeaderStyled, Links, MintBox, ImageMintProfileStyled, ImageContainer, MintText, MintButtonWrapperStyled, MintButtonText, IsWhitelistContainer, IsWhitelistCheckerText, IsWhitelistButtonContainer, IsWhitelistButtonWrapper, IsWhitelistStatusText, IsWhitelistButtonText, DutchStatsContainer, DutchPriceDetails, DutchMintButtonContainer, DutchInputQuantity, DutchSubmitButtonWrapper, DutchReclaimContainer, DutchReclaimSubContainer, ReclaimStat, ReclaimButtonWrapper, DesktopImage, MintContentStyled, DesktopImageContainer, DutchReclaimPlaceholderContainer, BodyLine } from './styled';
import useWindowDimensions from "../../utils/windowDimensions";

import useKiwamiContract from './interfaces/useKiwamiContract';


const Mint = () => {
    const { width } = useWindowDimensions()
    const { account } = useWeb3React()

    const [userAddress, setUserAddress] = useState(account ? account : '')
    // const [hasMintedMiceWL, setHasMintedMiceWL] = useState(false);
    // const [hasMintedPublicWL, setHasMintedPublicWL] = useState(false);
    // const [isMouseWhitelisted, setIsMouseWhitelisted] = useState(false);
    // const [isPublicWhitelisted, setIsPublicWhitelisted] = useState(false);
    // const [mouseProof, setMouseProof] = useState([]);kkkk
    // const [publicProof, setPublicProof] = useState([]);
    const [dutchMintQuantityInput, setDutchMintQuantityInput] = useState('');
    const [hasDutchEnded, setHasDutchEnded] = useState(false);
    const [dutchStartTime, setDutchStartTime] = useState(0);
    const [timeToNextPriceDec, setTimeToNextPriceDec] = useState("99:99");
    const [currentPrice, setCurrentPrice] = useState(0.0);
    const [remainingSupply, setRemainingSupply] = useState(7000);
    const [userBalance, setUserBalance] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0.0);
    const [totalRefund, setTotalRefund] = useState(0.0);
    const [totalCost, setTotalCost] = useState(0.0);

    const { Kiwami } = useKiwamiContract();

    useEffect(() => {
        // Currently need this in other to set isMouseWhitelisted/isPublicWhitelisted for wl mint button
        // checkIfWhitelisted()

        // if(userAddress) {
        //     Kiwami.userToHasMintedMiceWL(userAddress)
        //     .then((hasMintedMice) => {
        //         setHasMintedMiceWL(hasMintedMice)
        //     })
        //     .catch((err) => {
        //         console.log("userToHasMintedMiceWL error: ", err)
        //         setHasMintedMiceWL(false)
        //     })

        //     Kiwami.userToHasMintedPublicWL(userAddress)
        //     .then((hasMintedPublic) => {
        //         setHasMintedPublicWL(hasMintedPublic)
        //     })
        //     .catch((err) => {
        //         console.log("userToHasMintedPublicWL error: ", err)
        //         setHasMintedPublicWL(false)
        //     })   
        // }

    }, [userAddress])

    // For non-time-sensitive requests
    const refresh = async () => {
        if(userAddress){
            try {
                const totalSupply = parseInt(ethers.utils.formatUnits( await Kiwami.totalSupply(), 0));
                // console.log("total supply: ", totalSupply)
                // const totalSupplyParsed = ethers.utils.formatUnits(totalSupply, 0)
                // console.log(totalSupplyParsed)
                // const userBalance = parseInt(ethers.utils.formatUnits( await Kiwami.balanceOf(userAddress), 0));
                // console.log("user balance: ", userBalance)

                const newRemainingSupply = totalSupply > 7000 ? 0 : 7000 - totalSupply;
                setRemainingSupply(newRemainingSupply)
                // setUserBalance(userBalance)

                if(hasDutchEnded) {
                    const refundBatchLength = parseInt(ethers.utils.formatUnits(await Kiwami.userToTokenBatchLength(userAddress), 0));

                    var totalCost = 0.0;
                    var totalQuantity = 0;
                    for(let i = 0; i < refundBatchLength; i++) {
                        const refundBatchData = await Kiwami.userToTokenBatchPriceData(userAddress, i)
                        // console.log("batchData: ", refundBatchData)
                        const pricePaid = parseFloat(ethers.utils.formatEther(refundBatchData[0], 2))
                        // console.log("pricePaid: ", pricePaid)
                        const quantityMinted = parseInt(ethers.utils.formatUnits(refundBatchData[1], 0))
                        // console.log("quantityMinted: ", quantityMinted)

                        totalCost += (pricePaid);
                        totalQuantity += quantityMinted;
                    }
                    
                    // finalPrice and hasDutchEnded are always in-sync so we will always get correct final price
                    const totalRefundable = Math.round((totalCost - (totalQuantity * finalPrice) + Number.EPSILON) * 100) / 100
                    
                    setUserBalance(totalQuantity)
                    setTotalCost(totalCost)
                    setTotalRefund(totalRefundable)
                }


            } catch(err) {
                // console.log("Refresh error: ", err)
            }
        }
    };

    // For time-sensitive requests
    const refreshTime = async () => {
        if(dutchStartTime <= (Math.floor((Date.now() / 1000)))) {
            console.log("entering refresh...")
            const timeToNextPriceDec = 180 - ((Math.floor((Date.now() / 1000)) - dutchStartTime) % 180)
            const minutes = Math.floor(timeToNextPriceDec / 60);
            const seconds = timeToNextPriceDec - (minutes * 60);
            const formattedTimeToNextPriceDec = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
            setTimeToNextPriceDec(formattedTimeToNextPriceDec)

            if(dutchStartTime <= (Math.floor((Date.now() / 1000)))) {
                // console.log("setting current price...")
                if(finalPrice === 0.0) {
                    // console.log("finalprice not set...")
                    const timeSinceStart = (Math.floor((Date.now() / 1000)) - dutchStartTime)
                    const decrementsSinceStart = Math.floor(timeSinceStart / 180)
                    console.log(decrementsSinceStart)
                    const totalDecrement = decrementsSinceStart * 0.05
                    if(totalDecrement >= 0.4) {
                        setCurrentPrice(0.10)
                    } else {
                        setCurrentPrice( Math.round((0.5 - totalDecrement + Number.EPSILON) * 100) / 100 )
                    }
                }
            }

            try {
                const tempFinalPrice = parseFloat(ethers.utils.formatEther( await Kiwami.DA_FINAL_PRICE()), 2);
                if(tempFinalPrice) {
                    setFinalPrice(tempFinalPrice)
                    setHasDutchEnded(true)  // Comment this out to debug reclaim section
                }
            // console.log("Final price with type ", typeof tempFinalPrice)
            } catch(err) {
                console.log(err)
            } 
        }
    };

    refresh()

    // refreshTime()

    useInterval(() => {
        refresh()
    }, 5000)

    useInterval(() => {
        refreshTime()
    }, 1000)


    if(account && account !== userAddress) {
        setUserAddress(account ? account : '')
    }

    console.log("DA start time: ", dutchStartTime)
    if(dutchStartTime === 0) {
        Kiwami.DA_STARTING_TIMESTAMP()
        .then((startTime) => {
            const dutchStartTime = parseInt(ethers.utils.formatUnits(startTime, 0));
            // console.log("dutch start time: ", dutchStartTime)
            // console.log("current time: ", (Math.floor((Date.now() / 1000))))

            setDutchStartTime(dutchStartTime)
        })
        .catch((err) => {
            console.log(err)
        })
        
    }


    const handleDutchMintSubmit = async () => {
        if(userAddress) {
            try {
                console.log("Attempting to mint!")
                const amountPayable = ethers.utils.parseEther((dutchMintQuantityInput * currentPrice).toString())
                console.log("Using ", amountPayable)
                await Kiwami.mintDutchAuction(dutchMintQuantityInput, { value: amountPayable })
            } catch(err) {
                console.log(err)

            }
        }
    };

    const handleDutchMintChange = (event) => {
        if (event.target.value >= 1 && event.target.value <= 5) {
            setDutchMintQuantityInput(event.target.value);
        }
    };

    const handleRefund = async () => {
        try {
            console.log("Making refund claim...")
            await Kiwami.refundExtraETH();
        } catch(err) {
            console.log("refund error: ", err)
        }
    }

    return width > 650 ? (
        <ColStart className="w-100">
            <MintNavbar />
            <BackgroundStyled background={BgTeam}>
            <Profile background={MintProfile} />


            <DAContainer>
            <DAContent>
                <HeaderStyled>Dutch Auction</HeaderStyled>
                <DutchStatsContainer>
                    <DutchPriceDetails>
                            <span style={{marginRight: "2em"}}><span style={{fontSize: "1.05em", fontWeight: "650"}}>Quantity Remaining:</span> {remainingSupply}/7000</span>
                            <span style={{ textAlign: 'right', marginLeft: "2em" }}><span style={{textAlign: 'right', fontSize: "1.05em", fontWeight: "650"}}>Current Price:</span> {currentPrice} ETH</span>
                    </DutchPriceDetails>
                    <DutchPriceDetails>
                        <span style={{marginRight: "2em"}}></span>
                        <span style={{textAlign: 'right', marginLeft: "2em" }}> {finalPrice === 0.0 ? (currentPrice === 0.1 ? "00:00" : timeToNextPriceDec ) : "00:00" } until Next Discount</span>
                    </DutchPriceDetails>
                    
                </DutchStatsContainer>
                <DutchMintButtonContainer>
                    <DutchInputQuantity type="number" placeholder="Enter quantity" value={dutchMintQuantityInput} onChange={handleDutchMintChange}></DutchInputQuantity>
                    <DutchSubmitButtonWrapper className='hover-social' onClick={handleDutchMintSubmit}><IsWhitelistButtonText>{"MINT"}</IsWhitelistButtonText></DutchSubmitButtonWrapper>
                </DutchMintButtonContainer>
                {!hasDutchEnded ? 
                    <DutchReclaimPlaceholderContainer>
                        With Kiwami's Improved Dutch Auction -- even if you minted at the Ceiling Price, you'll only pay the final Resting Price. There's no penalty for minting early.
                    </DutchReclaimPlaceholderContainer> :
                    <>
                    <DutchReclaimPlaceholderContainer>
                        With Kiwami's Improved Dutch Auction -- even if you minted at the Ceiling Price, you'll only pay the final Resting Price. There's no penalty for minting early.
                    </DutchReclaimPlaceholderContainer>
                    <DutchReclaimContainer>
                        <BodyLine/>
                        <HeaderStyled>Reclaim</HeaderStyled>
                        <DutchReclaimSubContainer>
                            <DutchStatsContainer>
                                <ReclaimStat><span style={{fontWeight: "650"}}>Quantity Minted:</span> {userBalance}</ReclaimStat>
                                <ReclaimStat><span style={{fontWeight: "650"}}>Total Cost:</span> {totalCost} ETH</ReclaimStat>
                                <ReclaimStat><span style={{fontWeight: "650"}}>Total Refundable:</span> {totalRefund} ETH</ReclaimStat>
                            </DutchStatsContainer>
                            <ReclaimButtonWrapper className='hover-social' onClick={handleRefund}><IsWhitelistButtonText>{"Reclaim"}</IsWhitelistButtonText></ReclaimButtonWrapper>
                        </DutchReclaimSubContainer>
                    </DutchReclaimContainer>
                    <DutchReclaimPlaceholderContainer>
                        The Reclaim function is there for you to collect the difference between your mint price and the final sale price.
                    </DutchReclaimPlaceholderContainer>
                    </>
                }
            </DAContent>
            </DAContainer>
            </BackgroundStyled>
        </ColStart>
    ) : (
        <ColStart className="w-100">
        <MintNavbar />
        <BackgroundStyled background={BgTeam}>
            <ColCenter className="mt-50px">
                <MintContentStyled style={{marginRight: "0px", marginTop: "4rem"}}>
                    <HeaderStyled>Dutch Auction</HeaderStyled>
                    <DutchStatsContainer>
                        <DutchPriceDetails>
                            <span style={{marginRight: "2em"}}><span style={{fontSize: "1.05em", fontWeight: "650"}}>Quantity Remaining:</span> {remainingSupply}/7000</span>
                            <span style={{ textAlign: 'right', marginLeft: "2em" }}><span style={{textAlign: 'right', fontSize: "1.05em", fontWeight: "650"}}>Current Price:</span> {currentPrice} ETH</span>
                        </DutchPriceDetails>
                        <DutchPriceDetails>
                            <span style={{marginRight: "2em"}}></span>
                            <span style={{textAlign: 'right', marginLeft: "2em" }}>{finalPrice === 0.00 ? timeToNextPriceDec : "00:00" } Until Next Discount</span>
                        </DutchPriceDetails>
                        
                    </DutchStatsContainer>
                    <DutchMintButtonContainer>
                        <DutchInputQuantity type="text" placeholder="Enter quantity" value={dutchMintQuantityInput} onChange={handleDutchMintChange}></DutchInputQuantity>
                        <DutchSubmitButtonWrapper className='hover-social' onClick={handleDutchMintSubmit}><IsWhitelistButtonText>{"MINT"}</IsWhitelistButtonText></DutchSubmitButtonWrapper>
                    </DutchMintButtonContainer>
                    {!hasDutchEnded ? 
                        <DutchReclaimPlaceholderContainer>
                            With Kiwami's Improved Dutch Auction -- even if you minted at the Ceiling Price, you'll only pay the final Resting Price. There's no penalty for minting early.
                        </DutchReclaimPlaceholderContainer> :
                        <>
                        <DutchReclaimPlaceholderContainer>
                            With Kiwami's Improved Dutch Auction -- even if you minted at the Ceiling Price, you'll only pay the final Resting Price. There's no penalty for minting early.
                        </DutchReclaimPlaceholderContainer>
                        <DutchReclaimContainer>
                            <BodyLine/>
                            <HeaderStyled>Reclaim</HeaderStyled>
                            <DutchReclaimSubContainer>
                                <DutchStatsContainer>
                                    <ReclaimStat><span style={{fontWeight: "650"}}>Quantity Minted:</span> {userBalance}</ReclaimStat>
                                    <ReclaimStat><span style={{fontWeight: "650"}}>Total Cost:</span> {totalCost} ETH</ReclaimStat>
                                    <ReclaimStat><span style={{fontWeight: "650"}}>Total Refundable:</span> {totalRefund} ETH</ReclaimStat>
                                </DutchStatsContainer>
                                <ReclaimButtonWrapper className='hover-social' onClick={handleRefund}><IsWhitelistButtonText>{"Reclaim"}</IsWhitelistButtonText></ReclaimButtonWrapper>
                            </DutchReclaimSubContainer>
                        </DutchReclaimContainer>
                        <DutchReclaimPlaceholderContainer>
                            The Reclaim function is there for you to collect the difference between your mint price and the final sale price.
                        </DutchReclaimPlaceholderContainer>
                        </>
                    }
                </MintContentStyled>
            </ColCenter>
        </BackgroundStyled>
        </ColStart>
    )

}

// const DebugLog = styled.p`
//     margin: 5px;
//     color: white;
//     font-size: 1rem;
// `

export default Mint;

