import styled from "@emotion/styled"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"


export const ConnectButtonText = styled.div`
    position: relative;
    text-align: center;
    color: black;
    z-index: 3;
    transition: .5s;
    &:hover {
        font-size: 1.05em;
    }
`

export const ConnectButtonWrapperStyled = styled.div`
background-color: #fb2c94;
cursor: pointer;


font-family: akira;

display: block;
text-align: center;
position: relative;
overflow: hidden;
margin-right: 1rem;

z-index: 5;

position: relative;
color: #262626;
transition: .5s;

&:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 2;
  background: white;
}

&:hover {
  color: white;

  &:before {
    top: 0;
  }
}

font-size: 1.25rem;
width: 400px;
height: 55px;
line-height: 55px;

`


export const BodyLine = styled.hr`
  border: 1px solid white;
`

export const NavbarStyled = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: akira;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: #fb2c94;
  color: white;


  @media screen and (max-width: 1119px) {
    height: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  @media screen and (max-width: 768px) {
    height: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  &.mobile {
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

`

export const BodyNavbar = styled.div`
  position: fixed;
  z-index: -1;
  background-color: #1f0214;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100vh;
  transition: all 0.3s ease;

  padding-top: 170px;
  padding-left: 40px;
  padding-right: 100px;
  color: white;

  &.open {
    z-index: 3;
    bottom: 0;
    transition: all 0.5s ease;
  }

  @media screen and (max-width: 768px) {
    padding-top: 130px;
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const ButtonMobileNavStyled = styled.img`
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
  }
`

export const ButtonNavStyled = styled.img`
  height: 40px;
  width: auto;
  margin-left: 1rem;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease-in-out;
`

export const ButtonSocialStyled = styled.img`
  height: 40px;
  width: auto;
  margin-right: 1rem;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.1s ease-in-out;
  }
`


export const SocialWrapperStyled = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fb2c94;
  cursor: pointer;
  text-align: center;
  line-height: 54px;
  font-family: akira;
  font-size: 30px;
  margin-right: 1rem;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  
  z-index: 3;

  position: relative;
  color: #262626;
  transition: .5s;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 2;
    background: white;
  }

  &:hover {
    color: #fff;

    &:before {
      top: 0;
    }
  }
`

export const SocialIconStyled = styled(FontAwesomeIcon)`
  position: relative;
  text-align: center;
  color: black;
  z-index: 3;
`

export const OpenseaIconStyled = styled.img`
  position: relative;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;

  color: black;
  z-index: 3;
`

export const AnchorLinkStyled = styled(AnchorLink)`
  z-index: 2;
`

export const AnchorBodyLinkStyledText = styled(AnchorLink)`
text-decoration: none;
color: white;
font-family: akira;
font-size: 40px;
width: auto;
margin-left: 1rem;
margin-right: 1rem;
cursor: pointer;
z-index: 10;

@media screen and (max-width: 768px) {
  font-size: 30px;
}

&:hover {
  color: #fb2c94;
}

cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
  }
`

export const RouterBodyLinkStyledText = styled(Link)`
text-decoration: none;
color: white;
font-family: akira;
font-size: 40px;
width: auto;
margin-left: 1rem;
margin-right: 1rem;
cursor: pointer;
z-index: 10;

@media screen and (max-width: 768px) {
  font-size: 30px;
}

&:hover {
  color: #fb2c94;
}

cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
  }
`

export const RouterLinkStyledText = styled(Link)`
  text-decoration: none;
  color: white;
  font-family: akira;
  font-size: 24px;
  width: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  z-index: 10;

  &:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transform-origin: top left;

    transition: transform 0.3s;
  }

  &:after {
      content:'';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      right: 0;
      background-color: white;
      transform: scaleX(0);
      transform-origin: bottom right;

      transition: transform 0.3s;
  }

  &:hover {
      &:before {
          transform-origin: top right;
          transform: scaleX(1);
      }
      
      &:after {
          transform-origin: bottom left;
          transform: scaleX(1);
      }
  }
  }
`

export const AnchorLinkStyledText = styled(AnchorLink)`
  text-decoration: none;
  color: white;
  font-family: akira;
  font-size: 24px;
  width: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  z-index: 10;

  &:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transform-origin: top left;

    transition: transform 0.3s;
  }

  &:after {
      content:'';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      right: 0;
      background-color: white;
      transform: scaleX(0);
      transform-origin: bottom right;

      transition: transform 0.3s;
  }

  &:hover {
      &:before {
          transform-origin: top right;
          transform: scaleX(1);
      }
      
      &:after {
          transform-origin: bottom left;
          transform: scaleX(1);
      }
  }
}
`

export const LinkWrapper = styled.div`
  position: relative;
  display: block;
  font-family: akira;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    height: 3px;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s;
}

&:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left : 50%;
    height: 3px;
    transform: scaleX(0);
    transform-origin: top right;

    transition: transform 0.3s;
}

`
