import styled from '@emotion/styled';
import Modal from '@mui/material/Modal';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import { WalletInfo } from '../types';
import { colors, SUPPORTED_WALLETS } from '../utils/constants';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const GreenDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${colors.green};
`;

export const resetWalletConnector = (connector: AbstractConnector) => {
  if (connector && connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
    connector.walletConnectProvider = undefined;
  }
};

const WalletModal = ({ isOpen, onClose }: ModalProps) => {
  const { connector, active, activate } = useWeb3React();
  const onConnect = (connector: AbstractConnector) => async () => {
    resetWalletConnector(connector);
    activate(connector)
      .catch((err) => {
        console.log('err', err);
      })
      .then((res) => console.log('res', res));
    onClose();
  };

  const currWallet = SUPPORTED_WALLETS.find((wallet) => wallet.connector === connector);

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="Connect your web3 wallet">
      <Box>
        <div style={{ marginBottom: '12px' }}>{active && currWallet ? `Connected with ${currWallet.name}` : null}</div>
        {SUPPORTED_WALLETS.map(({ name, connector, iconURL }: WalletInfo) => (
          <Button key={name} onClick={onConnect(connector)}>
            <WalletName>
              {active && connector === currWallet?.connector ? <GreenDot /> : null} {name}
            </WalletName>
            <img src={iconURL} alt={`${name}`} />
          </Button>
        ))}
      </Box>
    </Modal>
  );
};

const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 2px solid white;
  background-color: black;
  color: white;
  padding: 24px;

  display: flex;
  flex-flow: column;
  font-size: 20px;
`;

const Button = styled.button`
  background: none;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid white;
  color: white;
  font-size: 20px;
  height: 70px;
  transition: all 1s ease-out;
  padding: 12px 20px;
  min-width: 350px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundGrey};
    color: black;
  }

  > img {
    width: 32px;
  }

  & + & {
    margin-top: 12px;
  }
`;

const WalletName = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  > div {
    margin-right: 8px;
  }
`;

export default WalletModal;
