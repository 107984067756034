import styled from "@emotion/styled"

export const BackgroundStyled = styled.div`
  background-color: #000000;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  
  min-height: 100vh;
  width: 100%;

`

export const ContentStyled = styled.div`
  margin-top: 20px;
  margin-left: 70px;
  margin-bottom: 30%;

  &.half {
    width: 50%;
  }

  @media screen and (min-width: 2500px) {
    margin-top: 60px;
    margin-left: 70px;

    &.half {
      width: 60%;
    }
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    margin-top: 60px;
    margin-left: 70px;

    &.half {
      width: 60%;
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    margin-top: 60px;
    margin-left: 70px;

    &.half {
      width: 70%;
    }
  }
  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    margin-top: 60px;
    margin-left: 70px;

    &.half {
      width: 70%;
    }
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    margin-top: 60px;
    margin-left: 50px;
    &.half {
      width: 80%;
    }
  }

  @media screen and (max-width: 1022px) {
    margin-top: 60px;
    margin-left: 0px;
    padding-bottom: 30px;

    &.half {
      width: calc(100% - 50px);
      margin-top: 60px;
      margin-left: 25px;
      margin-right: 25px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 60px;
    margin-left: 0px;
    padding-bottom: 30px;

    &.half {
      width: calc(100% - 60px);
      margin-top: 40px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
`
export const ALinkStyledText = styled.a`
  text-decoration: none;
  color: white;
  font-family: akira;
  font-size: 24px;
  width: auto;
  cursor: pointer;
  z-index: 10;

  &:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transform-origin: top left;

    transition: transform 0.3s;
  }

  &:after {
      content:'';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      right: 0;
      background-color: white;
      transform: scaleX(0);
      transform-origin: bottom right;

      transition: transform 0.3s;
  }

  &:hover {
      &:before {
          transform-origin: top right;
          transform: scaleX(1);
      }
      
      &:after {
          transform-origin: bottom left;
          transform: scaleX(1);
      }
  }
}
`;