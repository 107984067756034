import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import { WalletInfo } from '../types';
import METAMASK_ICON from '../assets/images/desktop/metamask.png';
// const METAMASK_ICON = require('../assets/images/desktop/metamask.png');


const INFURA_KEY = '03db2b7619dd4f8bad102698da9ea5e3';

export enum SupportedChainId {
  MAINNET = 1,
  RINKEBY = 4,
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [SupportedChainId.MAINNET, SupportedChainId.RINKEBY];

export const NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
};

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
});

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: NETWORK_URLS[SupportedChainId.MAINNET],
  appName: 'Kiwami',
  supportedChainIds: [1],
});

export const SUPPORTED_WALLETS: WalletInfo[] = [
  {
    name: 'Metamask',
    connector: injected,
    iconURL: METAMASK_ICON,
  },
];

export const colors = {
  blue: '#243DFF',
  green: '#276749',
  red: '#822727',
  black: '#080808',

  backgroundGrey: '#EEEEEE',
  backgroundGreen: '#C6F6D5',
  backgroundRed: '#FED7D7',
};
