import styledCustom from 'styled-components';
import styled from "@emotion/styled"


export const ImageBody = styled.div`
  margin: 20px;


  &:hover {
    margin: 0px;
    cursor: pointer;
  }

  &.mobile {
    display: flex;
    flex-direction: row:
    justify-content: center;
    align-items: center;
    margin: 30px;

    &:hover {
      margin: 30px;
      cursor: pointer;
    }

  }
`;

export const HoverBox = styled.img`
  position: absolute;
  left: -5.1%;
  top: -2.15%;
  z-index: 0;

  max-width: 110%;
  max-height: 110%;

  &.mobile {
    left: 6.1%;
    top: 8.4%;
    max-width: 87.5%;
    max-height: 87.5%;

    @media screen and (max-width: 380px) {
      left: 7.4%;
      top: 9.5%;
      max-width: 85.4%;
      max-height: 85.4%;
    }

    
  }

`;

export const ViewerBorder = styled.div`

`;

export const ViewerFrame = styled.div`
  position: fixed;
  z-index: 1000;
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  img {
    all: unset;
    width: 100%;
    max-width: 1250px;
    height: 100%;
    max-height: 90vh;
    animation-name: zoom;
    animation-duration: 0.5s;
  }
  
  @keyframes zoom {
    from {
      transform: scale(0)
    }
    to {
      transform: scale(1)
    }
  }

`;

export const CloseBtn = styledCustom.span`
  cursor: pointer;
  position: absolute;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  margin: 20px;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50%;
  animation-name: zoom;
  animation-duration: 0.5s;
`;

export const ProfileContainer = styled.div`
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  width: 80%;
  // height: 50%;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 1200px;
  max-height: 700px;
  min-width: 500px;
  min-height: 450px;
  display: flex;
  flex-direction: row;

  @media screen and (max-height: 500px) {
    margin-top: 400px;
    margin-bottom: 40px;
  }

  &.mobile {
    width: 90%;
    overflow-y: scroll;
    background-size: 100% 150%;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 100px;
    min-height: 100vh;
    background-position: top;
    background-attachment: scroll;
  }
`;

export const ImageTitle = styled.div`
  font-size: 2em;
  font-family: "Akira";
  font-weight: bold;
  color: white;
  margin: 1em;
  text-align: center;

  @media screen and (max-width: 1100px) {
    font-size: 1.8em;
  }

  @media screen and (max-width: 900px) {
    font-size: 1.6em;
  }

  @media screen and (max-width: 700px) {
    font-size: 1.4em;
  }


`;

export const ImageDescription = styled.div`
  font-family: "Montserrat";
  font-weight: bold;
  color: white;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-content: center;

  position: absolute;
  left: -5.1%;
  top: -2.15%;
  z-index: 0;

  max-width: 110%;
  max-height: 110%;
  width: 100%;
  height:100%;

  font-family: "Akira";
  // font-weight: bold;
`;

export const CenterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding-left: 1em;
  padding-bottom: 1px;
`;

export const TraitContainer = styled.div`
  width: 70%;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;


  @media screen and (max-width: 1100px) {
    font-size: 0.9em;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.8em;
  }

  @media screen and (max-width: 900px) {
    font-size: 0.75em;
  }

  @media screen and (max-width: 700px) {
    font-size: 0.68em;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.7em;
  }

  &.mobile {
    width: 90%;
    padding-left: 0rem;
    font-size: 0.5em;
    margin-top: 28px;

  }
`;

export const TraitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-family: "Montserrat";
  color: white;

`;

export const Trait = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;

  &.mobile {

  }
`;

export const TraitText = styled.div`
  &.mobile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

export const Trim = styled.img`
  width: 100%;
`;

export const SearchContainer = styled.div`
  margin-top: 66px;
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &.mobile {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 2000px;

  &.mobile {
    width: 95%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ButtonContainer = styled.div`
  width: 230px;
  height: 60px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // border-radius: 3px;
  
  position: relative;
  display: block;

  &.mobile {
    width: 160px;
    height: 50px;
  }

  &.clear {
  }
`;

export const TraitSearchCategory = styled.div`
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TraitsSelected = styled.div`
  width: 100%;
  text-align: left;
  font-family: "Montserrat";
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DropContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // border-radius: 3px;

  &.mobile {
    width: 150px;

  }
`;

// #fb2c94 pink
// #bf004e red

export const DropButton = styled.button`
  width: 190px;
  height: 50px;
  background-color: #bf004e;
  font-family: "Akira";
  border: 0px;
  color: white;
  padding: 8px;
  font-size: 16px;
  transition: 0.3s;
  border-radius: 3px;
  // box-shadow: rgba(0, 0, 0, 0.95) 0px 5px 15px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 19px 38px, rgba(0, 0, 0, 0.24) 0px 15px 12px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-color: white;
    color: black;
    font-size: 16px;
    border: none;
  }

  &.mobile {
    width: 140px;
    height: 40px;
    font-size: 12px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 5px;
  }

  &.clear {
    background-color: #fb2c94;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;

    &:hover {
      background-color: white;
      color: #bf004e;
      border: none;
    }
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: transparent;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;

  &:hover {
    display: block;
  }
`;

export const DropdownButton = styled.button`
  padding: 12px;
  display: block;
`

export const ClearContainer = styled.button`

`;

export const CenterColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:100%;
`;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-size: cover;
  background-position: bottom;
  background-repeat: repeat;
`;

export const ImageGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

`;

export const ImageBox = styled.img`
  max-width: 100%;
  max-height: 100%;
  // box-shadow: rgba(0, 0, 0, 0.9) 0px 3px 8px;
  // box-shadow: rgba(191, 0, 78, 0.3) 3px 3px, rgba(191, 0, 78, 0.2) 6px 6px, rgba(191, 0, 78, 0.1) 9px 9px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 54px 55px, rgba(0, 0, 0, 0.24) 0px -12px 30px, rgba(0, 0, 0, 0.24) 0px 4px 6px, rgba(0, 0, 0, 0.84) 0px 12px 13px, rgba(0, 0, 0, 0.18) 0px -3px 5px;
  border-radius: 3px;

  object-fit: cover;
  &:hover {
    cursor: pointer;

  }
`;

export const ProfileImageBox = styled.img`
  width: 100%;
  height: 100%; 
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 33%;

  &.mobile {
    width: 90%;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }
`;

export const DoubleImageContainer = styled.div`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 19px 38px, rgba(0, 0, 0, 0.5) 0px 15px 12px;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;

`;

export const OpenseaContainer = styled.div`
  
`;

