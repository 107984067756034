import styled from "@emotion/styled"

export const Profile = styled.div`
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

    min-height: 100vh;
    width: 70%;

    position: absolute;
    right: 50%;
    padding-top: 100px;
`

export const VerifyContainer = styled.div`
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content; center;
    align-items: center;

    padding-top: 100px;
`

export const VerifyContent = styled.div`
    font-family: montserrat;
    background-size: contain;
    background: linear-gradient(
        180deg,
        rgba(190, 0, 79, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
    );

    min-width: 320px;

    border: 4px solid #be004f;

    padding: 1rem;
    width: 50%;
    margin-left: 40%;

    margin-bottom: 27%;

`