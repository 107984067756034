import styled from "@emotion/styled";

export const Links = styled.div`

width: 100px;
text-color: black;
display: block;

> a + a,
a + div,
a + button {
  margin-left: 30px;
}
`;


export const MintButtonText = styled.div`
    position: relative;
    text-align: center;
    color: black;
    z-index: 1;
    transition: .5s;
    &:hover {
        font-size: 1.05em;
    }
`

export const MintButtonWrapperStyled = styled.div`
width: 200px;
height: 50px;
background-color: #fb2c94;
cursor: pointer;

line-height: 54px;
font-family: akira;
font-size: 1rem;

display: block;
text-align: center;
position: relative;
overflow: hidden;


z-index: 1;

position: relative;
color: #262626;
transition: .5s;

&:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 1;
  background: white;
}

&:hover {
  color: white;

  &:before {
    top: 0;
  }
}

font-size: 1rem;
width: 300px;
height: 50px;
line-height: 50px;


@media screen and (min-width: 750px) {
    font-size: 1.25rem;
    width: 300px;
    height: 50px;
    line-height: 50px;
}


@media screen and (min-width: 850px) {
    font-size: 1.25rem;
    width: 350px;
    height: 55px;
    line-height: 55px;
}


@media screen and (min-width: 1050px) {
    font-size: 1.25rem;
    width: 400px;
    height: 55px;
    line-height: 55px;
}

@media screen and (min-width: 1350px) {
    font-size: 1.5rem;
    width: 500px;
    height: 60px;
    line-height: 60px;
}

@media screen and (min-width: 1600px) {
    font-size: 2rem;
    width: 600px;
    height: 70px;
    line-height: 70px;
}
`

export const ContentStyled = styled.div`
  background-size: contain;
  background: rgb(190, 0, 79);
  background: linear-gradient(
    180deg,
    rgba(190, 0, 79, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  border: 4px solid #be004f;
  margin-top: 20px;
  max-width: 1200px;
  min-width: 550px;

  padding: 1rem;

  &.half {
    width: 50%;
  }

  &.mint {
    width: 70%;
    // display: inline-block;
    padding: 0px;
    justify-content: space-between;
    // bottom: 0;
  }

  @media screen and (min-width: 1600px){
    border: 10px solid #be004f;
    
  }

  @media screen and (max-width: 830px){

  }
`

export const HeaderStyled = styled.div`
  font-size: 2rem;
  font-family: "Akira";
  font-weight: bold;
  color: white;
//   margin-left: 80px;
  margin-bottom: 15px;
`

export const MintBox = styled.div`
//   padding-left: 15%;
//   padding-right: 5%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  width: 65%;
  height: 100%;

  @media screen and (min-width: 750px) {
    font-size: 1rem;
    }

  @media screen and (min-width: 850px) {
    font-size: 1.25rem;
    }

  @media screen and (min-width: 1050px) {
    font-size: 1.25rem;
    }

  @media screen and (min-width: 1350px) {
    font-size: 1.5rem;

    }

  @media screen and (min-width: 1600px) {
    font-size: 2rem;

    }



`

export const MintText = styled.div`
    font-family: "Akira";
    color: white;
    margin-bottom: 10px;
    text-align: end;
`

export const ImageContainer = styled.div`
  // this isnt use?
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

`

export const DesktopImageContainer = styled.div`
    height: 100vh;
    width: 40vw;
    position: sticky;
    // overflow-x: hidden;
    right: 0;
    // bottom: 0;

    z-index: 2;

`

export const DesktopImage = styled.img`
// display: block;
margin: 0px;
padding: 0px;
height: 100vh;
position: absolute;
right: 0%;
// bottom: 0;
// overflow-x: hidden;
z-index: 2;


`


export const ImageMintProfileStyled = styled.img`
  display: flex;
  margin: 0px;
  padding: 0px;
  width: 35%;
  min-width: 200px;

  @media screen and (min-width: 1600px) {
      width: 400px;
  }
`

// IS WHITELIST STYLING
export const IsWhitelistContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const IsWhitelistCheckerText = styled.div`
    color: white;
    margin-bottom: 10px;
    text-align: left;
`

export const IsWhitelistButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const IsWhitelistStatusText = styled.div`
    width: 60%;
    color: white;
    // margin-bottom: 10px;
    // margin-top: 1.1rem;
    padding: 1rem;
    text-align: center;
    justify-content: center;
    
    transition: 3s;
`

export const IsWhitelistButtonText = styled.div`
    position: relative;
    text-align: center;
    color: black;
    z-index: 1;
    transition: .5s;
    &:hover {
        font-size: 1.05em;
    }
`

export const IsWhitelistButtonWrapper = styled.div`
    width: 200px;
    height: 50px;
    background-color: #fb2c94;
    cursor: pointer;

    line-height: 54px;
    font-family: akira;
    font-size: 1rem;

    display: block;
    text-align: center;
    position: relative;
    overflow: hidden;


    z-index: 1;

    position: relative;
    color: #262626;
    transition: .5s;

    &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 1;
    background: white;
    }

    &:hover {
    color: white;

    &:before {
        top: 0;
    }
    }

    font-size: 1rem;
    width: 300px;
    height: 50px;
    line-height: 50px;


    @media screen and (min-width: 750px) {
        font-size: 0.9rem;
        width: 200px;
        height: 45px;
        line-height: 45px;
    }


    @media screen and (min-width: 850px) {
        font-size: 1rem;
        width: 250px;
        height: 50px;
        line-height: 50px;
    }


    @media screen and (min-width: 1050px) {
        font-size: 1.1rem;
        width: 300px;
        height: 55px;
        line-height: 55px;
    }

    @media screen and (min-width: 1350px) {
        font-size: 1.25rem;
        width: 400px;
        height: 55px;
        line-height: 55px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 1.5rem;
        width: 500px;
        height: 60px;
        line-height: 60px;
    }
`

// DUTCH AUCTION STYLING
export const DutchStatsContainer = styled.div`
    display: flex:
    flex-direction: column;
    color: white;

    @media screen and (max-width: 600px) {
        flex-direction: row;
    }


    @media screen and (min-width: 750px) {
        font-size: 1rem;
    }
    
    @media screen and (min-width: 850px) {
        font-size: 1rem;
    }

    @media screen and (min-width: 1050px) {
        font-size: 1rem;
    }

    @media screen and (min-width: 1350px) {
        font-size: 1.25rem;
    }

    @media screen and (min-width: 1600px) {
        font-size: 1.5rem;
    }
`

export const DutchPriceDetails = styled.p`
    display: flex;
    justify-content: space-between;
`

export const DutchMintButtonContainer = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    margin-top: 50px;
`

export const DutchSubmitButtonWrapper = styled.div`
    background-color: #fb2c94;
    cursor: pointer;
    border: none;

    font-family: akira;
    font-size: 1rem;

    max-width: 500px;

    text-align: center;
    overflow: hidden;

    margin-left: 2rem;
    margin-right: 1rem;


    z-index: 1;

    position: relative;
    transition: .5s;

    &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 1;
    background: white;
    }

    &:hover {
        color: white;

        &:before {
            top: 0;
        }
    }

    width: 50%;
    height: 45px;
    line-height: 45px;

    // This is when the page starts to break
    @media screen and (min-width: 600px) {
        font-size: 1rem;
        width: 200px;
        height: 45px;
        line-height: 45px;
    }

    @media screen and (min-width: 750px) {
        font-size: 1rem;
        width: 200px;
        height: 45px;
        line-height: 45px;
    }

    @media screen and (min-width: 850px) {
        font-size: 1.1rem;
        width: 250px;
        height: 50px;
        line-height: 50px;
    }

    @media screen and (min-width: 1050px) {
        font-size: 1.25rem;
        width: 300px;
        height: 55px;
        line-height: 55px;
    }

    @media screen and (min-width: 1350px) {
        font-size: 1.25rem;
        width: 400px;
        height: 55px;
        line-height: 55px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 1.5rem;
        width: 500px;
        height: 60px;
        line-height: 60px;
    }

`

export const BodyLine = styled.hr`
  width: 100%;
  border: 2px solid rgba(190, 0, 79, 0.5);
  margin-top: 0rem;
  margin-bottom: 1rem;
  display: flex;
`

export const DutchInputQuantity = styled.input`
    background-color: rgba(190, 0, 79, 0.5);
    text-align: center;


    color: white;
    padding: 0px;
    margin-left: 1rem;
    margin-right: 2rem;
    position: relative;
    border: none;
    transition: 0.5s;
    // display: block;

    ::placeholder {
        color: rgba(190, 0, 79, 1);
    }

    &.onfocus {
        border: 1px solid rgba(190, 0, 79, 1);
    }

    font-size: 1rem;
    width: 50%;
    height: 45px;
    padding-bottom: 2px;

    max-width: 500px;


    @media screen and (min-width: 600px) {
        font-size: 1rem;
        width: 200px;
        height: 45px;
        line-height: 45px;
    }

    @media screen and (min-width: 750px) {
        font-size: 1.1rem;
        width: 200px;
        height: 45px;
        padding-bottom: 2px;

    }


    @media screen and (min-width: 850px) {
        font-size: 1.25rem;
        width: 250px;
        height: 50px;
        padding-bottom: 2px;

    }


    @media screen and (min-width: 1050px) {
        font-size: 1.25rem;
        width: 300px;
        height: 55px;
        padding-bottom: 2px;

    }

    @media screen and (min-width: 1350px) {
        font-size: 1.25rem;
        width: 400px;
        height: 55px;
        padding-bottom: 2px;

    }

    @media screen and (min-width: 1600px) {
        font-size: 1.5rem;
        width: 500px;
        height: 60px;
        padding-bottom: 2px;

    }
`

export const DutchReclaimContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    color: white;
    

    @media screen and (min-width: 750px) {
        font-size: 1rem;
    }
    
    @media screen and (min-width: 850px) {
        font-size: 1.25rem;
    }

    @media screen and (min-width: 1050px) {
        font-size: 1.25rem;
    }

    @media screen and (min-width: 1350px) {
        font-size: 1.5rem;
    }

    @media screen and (min-width: 1600px) {
        font-size: 2rem;
    }
`

export const DutchReclaimPlaceholderContainer = styled.div`
    // display: flex;
    flex-direction: column;
    margin-top: 4rem;
    // width: 50%;

    color: white;
    

    @media screen and (min-width: 750px) {
        font-size: 0.8rem;
    }
    
    @media screen and (min-width: 850px) {
        font-size: 1rem;
    }

    @media screen and (min-width: 1050px) {
        font-size: 1.1rem;
    }

    @media screen and (min-width: 1350px) {
        font-size: 1.25rem;
    }

    @media screen and (min-width: 1600px) {
        font-size: 1.25rem;
    }
`

export const ReclaimStat = styled.p`
    text-align: left;
`

export const DutchReclaimSubContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

`

export const ReclaimButtonText = styled.div`
    position: relative;
    text-align: center;
    color: black;
    z-index: 1;
    transition: .5s;
    &:hover {
        font-size: 1.05em;
    }
`

export const ReclaimButtonWrapper = styled.div`
width: 200px;
height: 50px;
background-color: #fb2c94;
cursor: pointer;
border: none;

line-height: 54px;
font-family: akira;
font-size: 1rem;


text-align: center;
overflow: hidden;

margin-left: 2rem;
margin-right: 1rem;


z-index: 1;

position: relative;
transition: .5s;

&:before {
content: "";
position: absolute;
top: 100%;
left: 0;
width: 100%;
height: 100%;
transition: 0.5s;
z-index: 1;
background: white;
}

&:hover {
    color: white;

    &:before {
        top: 0;
    }
}

// This is when the page starts to break
@media screen and (min-width: 600px) {
    font-size: 1rem;
    width: 200px;
    height: 45px;
    line-height: 45px;
}

@media screen and (min-width: 750px) {
    font-size: 1rem;
    width: 200px;
    height: 45px;
    line-height: 45px;
}

@media screen and (min-width: 850px) {
    font-size: 1.1rem;
    width: 200px;
    height: 50px;
    line-height: 50px;
}

@media screen and (min-width: 1050px) {
    font-size: 1.25rem;
    width: 250px;
    height: 55px;
    line-height: 55px;
}

@media screen and (min-width: 1350px) {
    font-size: 1.25rem;
    width: 250px;
    height: 55px;
    line-height: 55px;
}

@media screen and (min-width: 1600px) {
    font-size: 1.5rem;
    width: 300px;
    height: 60px;
    line-height: 60px;
}

`

export const MintContentStyled = styled.div`
  font-family: montserrat;
  background-size: contain;
  background: rgb(190, 0, 79);
  background: linear-gradient(
    180deg,
    rgba(190, 0, 79, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  border: 4px solid #be004f;
  margin-top: 20px;
//   max-width: 1200px;
  min-width: 320px;

  margin-right: 2vw;

  padding: 1rem;
  width: 80%;



  @media screen and (min-width: 1600px){
    border: 5px solid #be004f;
    
  }

  @media screen and (max-width: 850px){
    // width: 65%;
  }
`


export const Profile = styled.div`
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

    min-height: 100vh;
    width: 70%;

    position: absolute;
    right: 55%;

    padding-top: 200px;
`

export const DAContainer = styled.div`
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content; center;
    align-items: center;

    padding-top: 200px;
`

export const DAContent = styled.div`
    font-family: montserrat;
    background-size: contain;
    background: linear-gradient(
        180deg,
        rgba(190, 0, 79, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
    );

    min-width: 320px;

    border: 4px solid #be004f;

    padding: 1rem;
    width: 50%;
    margin-left: 40%;

    margin-bottom: 10%;
`