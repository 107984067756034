import styled from "@emotion/styled"


export const BackgroundStyled = styled.div`
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  min-height: 100vh;
  width: 100%;
`

export const KiwamiLineStyled = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  font-family: "Akira";
  background: #bf004e;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  height: 50px;
`