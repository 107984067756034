import { useEffect, useState } from 'react';
import './styles/App.css';
import { ImageViewer } from "./viewer/ImageViewer"
import { Masonry } from "masonic";
import builkImages from './kiwami.json';
import Navbar from '../../layouts/Navbar';
import GalleryBackground from '../../assets/images/desktop/bg_strategy.jpg';
import { ColStart } from '../../components/general_styled';
import { SearchContainer, GalleryContainer, ButtonContainer, DropButton, DropContainer, CenterColumn, Background, TraitSearchCategory, TraitsSelected } from './viewer/styled-components/StyledComponents';
import md5 from 'md5';
import useWindowDimensions from "../../utils/windowDimensions"
export class DeepSet<t> extends Set<t> {

  add(o: t) {
    for (let i of this)
      if (this.deepCompare(o, i))
        return this;
    super.add.call(this, o);
    return this;
  };

  private deepCompare(o: any, i: any) {
    return JSON.stringify(o) === JSON.stringify(i)
  }
}
export type ArrayImageData = ImageData[]

export interface ImageData {
  name: string
  description: string
  external_url: string
  image: string
  attributes: Attribute[]
}

export interface Attribute {
  trait_type: string
  value: string
}

function App() {
  const [filterCategory, setFilterCatagory] = useState<Map<string, DeepSet<Attribute>>>(new Map());
  const [filterImage, setFilterImage] = useState<ArrayImageData>([]);
  const { width } = useWindowDimensions()

  useEffect(() => {
    (builkImages as ArrayImageData).forEach((image: { attributes: any; }) => {
      for (const attribute of image.attributes) {
        let attributesList: DeepSet<Attribute> | undefined;
        const traitType = attribute.trait_type
        if (filterCategory.has(traitType)) {
          attributesList = filterCategory.get(traitType);
          attributesList?.add(attribute);
        }
        else {
          attributesList = new DeepSet();
          attributesList?.add(attribute);
          filterCategory.set(traitType, attributesList)
        }
      }
    });
    //  deep copy to get the virtual dom to rerender the page
    setFilterCatagory(new Map(filterCategory));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [selectedFilterMap, setSelectedFilterMap] = useState<Map<string, Set<string>>>(new Map());

  useEffect(() => {

    const filterk = (builkImages as ArrayImageData).filter(function (currentElement: { attributes: Array<Attribute>; }) {
      let and = true;
      for (const traitsCatagory of selectedFilterMap.keys()) {
        let catExist = false
        for (const attributeValue of selectedFilterMap.get(traitsCatagory) as Set<string>) {
          for (const currentAttributesValue of currentElement.attributes) {

            catExist = catExist || (traitsCatagory === currentAttributesValue.trait_type && attributeValue === currentAttributesValue.value);
          }
        }
        and = and && catExist
      }
      return and;
    });
    
    setFilterImage(filterk) 
  }, [selectedFilterMap]);
  const removeItemfromSet = (map: Map<string, Set<string>>, itemToRemove: Attribute) => {
    const set = map.get(itemToRemove.trait_type);
    set?.delete(itemToRemove.value)
    if(set?.size === 0)
    {
      map.delete(itemToRemove.trait_type);
    }
  }
  const addToSet = (element: Attribute) => {
    let set: Set<string> | undefined;
    if (selectedFilterMap.has(element.trait_type)) {
      set = selectedFilterMap.get(element.trait_type);
      set?.add(element.value);
    }
    else {
      set = new DeepSet();
      set?.add(element.value);
      selectedFilterMap.set(element.trait_type, set)

    }
  }

  return (
    <ColStart className="w-100">
      <Navbar />
      <Background style={{backgroundImage: `url(${GalleryBackground})`}}></Background>
      <CenterColumn>
      <GalleryContainer className={width > 650 ? 'desktop' : 'mobile' }>
        <SearchContainer className={width > 650 ? 'desktop' : 'mobile' }>
          {Array.from(filterCategory.keys()).map((traitsCatogory) => {
            let traitsToSearch = selectedFilterMap.get(traitsCatogory) ? Array.from(selectedFilterMap.get(traitsCatogory)!).join(', ') : "All"
            return <DropContainer className={width > 650 ? 'desktop' : 'mobile' }>
              <ButtonContainer className={width > 650 ? 'dropdown desktop' : 'dropdown mobile' }>
                <DropButton className={width > 650 ? 'dropbtn desktop' : 'dropbtn mobile' } >
                  <TraitSearchCategory>{traitsCatogory}</TraitSearchCategory>
                  <TraitsSelected>{traitsToSearch!}</TraitsSelected>
                </DropButton>
              <div className={'dropdown-content'} style={{ width: width > 650 ? "188px" : "138px", marginLeft: width > 650 ? "0px" : "8px", borderRadius: "3px"}}>
                {
                  (Array.from(filterCategory.get(traitsCatogory) ?? [])).map((traitsElement) => {
                    return <button  className='dropdown-content-button' style={{borderRadius: "3px", backgroundColor: selectedFilterMap.get(traitsElement.trait_type)?.has(traitsElement.value) ? 'white' : '', color: selectedFilterMap.get(traitsElement.trait_type)?.has(traitsElement.value) ? 'black' : ''}} onClick={() => {
                      selectedFilterMap.get(traitsElement.trait_type)?.has(traitsElement.value) ? removeItemfromSet(selectedFilterMap, traitsElement) : addToSet(traitsElement);
                      setSelectedFilterMap(new Map(selectedFilterMap))
                    }} >
                      {traitsElement.value}
                    </button>
                  })
                }
              </div>
            </ButtonContainer></DropContainer>
          })}
          <DropContainer className={width > 650 ? 'desktop' : 'mobile' }>
              <ButtonContainer className={width > 650 ? 'dropdown desktop clear' : 'dropdown mobile clear' }>
                <DropButton className={width > 650 ? 'dropbtn desktop clear' : 'dropbtn mobile clear' } onClick={() => setSelectedFilterMap(new Map())}>
                  <div>{"Clear"}</div>
                </DropButton>
              </ButtonContainer>
          </DropContainer>
        </SearchContainer>
            {<Masonry style={{marginTop: "25px"}} items={filterImage} key={md5(JSON.stringify(filterImage))} overscanBy={1}
              render={(element) => {
              // console.log(data.data); grid-area
              return <ImageViewer element={element}>
              </ImageViewer>
            }} />
            }

      </GalleryContainer>
      </CenterColumn>
      {/* <Footer /> */}
      </ColStart>
  );
}

export default App;
