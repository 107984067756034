import { contractAbi, contractAddress } from '../contracts/kiwamiContract';
import { useWeb3React } from '@web3-react/core';
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'


// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value){
    try {
        // console.log("address: " + value)
      return getAddress(value)
    } catch {
        // console.log("not address")
      return false
    }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
    const parsed = isAddress(address)
    if (!parsed) {
        throw Error(`shortenAddress Invalid 'address' parameter '${address}'.`)
    }
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// account is not optional
function getSigner(library, account) {
    return library.getSigner(account).connectUnchecked()
}
  
// account is optional
function getProviderOrSigner(library, account) {
    // console.log("Is signer? " + account)
    return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address, ABI, library, account) {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`getContract Invalid 'address' parameter '${address}'.`)
    }

    return new Contract(address, ABI, getProviderOrSigner(library, account))
}


export default function useKiwamiContract() {
    const { library, account, provider } = useWeb3React();
    var Kiwami;
    try {
        Kiwami = getContract(contractAddress, contractAbi, library, account ? account : undefined );
        
    } catch(error) {
        console.error("Failed to get contract", error)
        console.error("contract address: " + contractAddress)
        console.log("library: ", library)
        console.log("account: ", account)
        console.log("provider: ", provider)
    }

    
    return { Kiwami };
}