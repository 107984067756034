import { useWeb3React } from '@web3-react/core';
import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';

import BgTeam from "../../assets/images/desktop/bg_team.jpg"
import WhitelistMintProfile from "../../assets/images/desktop/whitelistmint.png"
import { ColStart, ColCenter } from "../../components/general_styled"
import MintNavbar from "../../layouts/MintNavbar"
import { BackgroundStyled } from '../Home/styled';
import { Profile, WhitelistedMintContainer, WhitelistedMintContent } from './styled';
import { HeaderStyled, IsWhitelistButtonText, ReclaimButtonWrapper, IsWhitelistContainer, IsWhitelistCheckerText, IsWhitelistStatusText, IsWhitelistButtonContainer, BodyLine  } from '../Mint/styled';
// import { ContentStyled, HeaderStyled, Links, MintBox, ImageMintProfileStyled, ImageContainer, MintText, MintButtonWrapperStyled, MintButtonText, IsWhitelistContainer, IsWhitelistCheckerText, IsWhitelistButtonContainer, IsWhitelistButtonWrapper, IsWhitelistStatusText, IsWhitelistButtonText, DutchStatsContainer, DutchPriceDetails, DutchMintButtonContainer, DutchInputQuantity, DutchSubmitButtonWrapper, DutchReclaimContainer, DutchReclaimSubContainer, ReclaimStat, ReclaimButtonWrapper, DesktopImage, MintContentStyled, DesktopImageContainer, DutchReclaimPlaceholderContainer, BodyLine } from './styled';
import useWindowDimensions from "../../utils/windowDimensions";

import useKiwamiContract from '../Mint/interfaces/useKiwamiContract';


const WhitelistedMint = () => {
    const { width } = useWindowDimensions()
    const { account } = useWeb3React()

    const [userAddress, setUserAddress] = useState(account ? account : '')
    const [isMouseWhitelisted, setIsMouseWhitelisted] = useState(false);
    const [isPublicWhitelisted, setIsPublicWhitelisted] = useState(false);
    const [mouseProof, setMouseProof] = useState([]);
    const [publicProof, setPublicProof] = useState([]);


    const { Kiwami } = useKiwamiContract();

    console.log("isMouseWhitelisted: ", isMouseWhitelisted)
    console.log("isPublicWhitelisted: ", isPublicWhitelisted)
    console.log("mouseProof: ", mouseProof)
    console.log("publicProof: ", publicProof)



    useEffect(() => {
        // Currently need this in other to set isMouseWhitelisted/isPublicWhitelisted for wl mint button
        // if(userAddress && userAddress !== '') {
        //     fetch('https://merkednodel.herokuapp.com/proof', {
        //     method: 'POST',
        //     credentials: 'include',
        //     headers: {
        //         'Content-Type': 'application/json', 7
        //     },
        //     body: JSON.stringify({ userAddress: userAddress }),
        //     })
        //     .then((res) => res.json())
        //     .then((res) => {
        //         if(res.errormessage) {
        //             console.log(res.errormessage)
        //             setIsMouseWhitelisted(false)
        //             setIsPublicWhitelisted(false)
        //             setPublicProof([])
        //             setMouseProof([])
        //         } else {
        //             // console.log("In whitelist checker")
        //             // console.log(res)
        //             setPublicProof(res.publicProof)
        //             setMouseProof(res.mouseProof)

        //             Kiwami.verifyMouseSender(userAddress, res.mouseProof)
        //             .then((isVerifiedOnChain) => {
        //                 // console.log("verify mouse: ", isVerifiedOnChain)
        //                 if(isVerifiedOnChain) {
        //                     setIsMouseWhitelisted(true)
        //                 } else {
        //                     setIsMouseWhitelisted(false)
        //                 }
        //                 console.log("Mouse Verify Resp: ", isVerifiedOnChain)

        //             })
        //             .catch((err) => {
        //                 console.log(`verify mouse err with inputs: ${userAddress} ${res.publicProof}`)
        //             })

        //             Kiwami.verifyPublicSender(userAddress, res.publicProof)
        //             .then((isVerifiedOnChain) => {
        //                 // console.log("verify public: ", isVerifiedOnChain)
        //                 if(isVerifiedOnChain) {
        //                     setIsPublicWhitelisted(true)
        //                 } else {
        //                     setIsPublicWhitelisted(false)
        //                 }
        //                 console.log("Public Verify Resp: ", isVerifiedOnChain)

        //             })
        //             .catch(() => {
        //                 console.log(`verify public err with inputs: ${userAddress} ${res.publicProof}`)
        //             })
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        // }

    }, [userAddress])



    // Currently need this in other to set isMouseWhitelisted/isPublicWhitelisted for wl mint button
    async function checkIfWhitelisted() {
        try {
            if(userAddress && userAddress !== '') {
                var res = await fetch('https://merkednodel.herokuapp.com/proof', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userAddress: userAddress }),
                })

                res = await res.json()

                
                if(res.errormessage) {
                    console.log(res.errormessage)
                    setIsMouseWhitelisted(false)
                    setIsPublicWhitelisted(false)
                    setPublicProof([])
                    setMouseProof([])
                } else {
                    // console.log("In whitelist checker")
                    // console.log(res)
                    // setIsMouseWhitelisted(false)
                    // setIsPublicWhitelisted(false)
                    setPublicProof(res.publicProof)
                    setMouseProof(res.mouseProof)
                }

                return { thePublicProof: res.publicProof, theMouseProof: res.mouseProof }
                        
                        // try {
                        // const isMouseVerifiedOnChain = await Kiwami.verifyMouseSender(userAddress, res.mouseProof)
                        // } catch(err) {
                        //     console.log(`verify mouse err with inputs: ${userAddress} ${res.publicProof}`)
                        // }
                        //     // console.log("verify mouse: ", isVerifiedOnChain)
                        // if(isMouseVerifiedOnChain) {
                        //     setIsMouseWhitelisted(true)
                        // } else {
                        //     setIsMouseWhitelisted(false)
                        // }
                        // console.log("Mouse Verify Resp: ", isVerifiedOnChain)



                        // Kiwami.verifyPublicSender(userAddress, res.publicProof)
                        // .then((isVerifiedOnChain) => {
                        //     // console.log("verify public: ", isVerifiedOnChain)
                        //     if(isVerifiedOnChain) {
                        //         setIsPublicWhitelisted(true)
                        //     } else {
                        //         setIsPublicWhitelisted(false)
                        //     }
                        //     console.log("Public Verify Resp: ", isVerifiedOnChain)

                        // })
                        // .catch(() => {
                        //     console.log(`verify public err with inputs: ${userAddress} ${res.publicProof}`)
                        // })
                    
            }
        } catch (err) {
            console.log(err)
        }

        return { thePublicProof: [], theMouseProof: [] }
    }
    





    

    // checkIfWhitelisted()

    // For non-time-sensitive requests
    // const refresh = async () => {
    //     if(userAddress){
    //         try {
    //             // Kiwami.userToHasMintedMiceWL(userAddress)
    //             // .then((hasMintedMice) => {
    //             //     setHasMintedMiceWL(hasMintedMice)
    //             // })
    //             // .catch((err) => {
    //             //     console.log("userToHasMintedMiceWL error: ", err)
    //             //     setHasMintedMiceWL(false)
    //             // })

    //             // Kiwami.userToHasMintedPublicWL(userAddress)
    //             // .then((hasMintedPublic) => {
    //             //     setHasMintedPublicWL(hasMintedPublic)
    //             // })
    //             // .catch((err) => {
    //             //     console.log("userToHasMintedPublicWL error: ", err)
    //             //     setHasMintedPublicWL(false)
    //             // })   
    //         } catch(err) {
    //             console.log("Refresh error: ", err)
    //         }
    //     }
    // };

    // useInterval(() => {
    //     refresh()
    // }, 10000)


    if(account && account !== userAddress) {
        setUserAddress(account ? account : '')
    }


    // async function whitelistMintHandler() {
    //     if(userAddress && userAddress !== '') {
    //         try {
    //             const hasMintedMiceWL = await Kiwami.userToHasMintedMiceWL(userAddress)
    //             const hasMintedPublicWL = await Kiwami.userToHasMintedPublicWL(userAddress)

    //             if(isMouseWhitelisted && mouseProof && !hasMintedMiceWL) {
    //                 // const payableMouseWLMint = ethers.utils.parseEther("0.0")
    //                 const mintResult = await Kiwami.mintMouseWL(mouseProof)
    //                 console.log("attempted mouse mint: ", mintResult)
    //             } else if(isPublicWhitelisted && publicProof && !hasMintedPublicWL) {
    //                 const payablePublicWLMint = ethers.utils.parseEther("0.1")  // @TODO: will need to change if ending dutch auction price changes
                
    //                 const mintResult = await Kiwami.mintPublicWL(publicProof, { value: payablePublicWLMint })
    //                 console.log("attempted public mint: ", mintResult)

    //             } else {
    //                 // @TODO: notify user they are not on whitelist or that they already minted
    //                 console.log("Cant mint. Not on whitelist or already minted...")
    //             }    
    //         } catch(err) {
    //             console.log(err)
    //         }
    //     }
    // };

    async function publicWhitelistMintHandler() {
        if(userAddress && userAddress !== '') {
            try {
                // const hasMintedPublicWL = await Kiwami.userToHasMintedPublicWL(userAddress)
                var proofs = await checkIfWhitelisted()

                const payablePublicWLMint = ethers.utils.parseEther("0.08")  // @TODO: will need to change if ending dutch auction price changes
                const mintResult = await Kiwami.mintPublicWL(proofs.thePublicProof, { value: payablePublicWLMint })
                console.log("attempted public mint: ", mintResult)

            } catch(err) {
                alert(err.error.message)
            }
        }
    }

    async function miceWhitelistMintHandler() {
        if(userAddress && userAddress !== '') {
            try {
                // const hasMintedMiceWL = await Kiwami.userToHasMintedMiceWL(userAddress)

                var proofs = await checkIfWhitelisted()
                // console.log(proofs)
                    // const payableMouseWLMint = ethers.utils.parseEther("0.0")
                const mintResult = await Kiwami.mintMouseWL(proofs.theMouseProof)
                console.log("attempted mouse mint: ", mintResult)

                // else if (hasMintedMiceWL) {
                //     // @TODO: notify user they are not on whitelist or that they already minted
                //     alert("You already minted")
                // }    
            } catch(err) {
                if(err.error) {
                    alert(err.error.message)
                }
                else {
                    alert(err)
                }
            }
        }
    }




    // <html style={{minHeight: "100%", position: "relative"}}>
    // <body style={{height: "100%"}}>
    // <DesktopImageContainer>
    //     <DesktopImage src={MintProfile}/>
    // </DesktopImageContainer>
    // </body>
    // </html>

    return width > 650 ? (
        <ColStart className="w-100">
            <MintNavbar />
            <BackgroundStyled background={BgTeam}>
            <Profile background={WhitelistMintProfile} />
            <WhitelistedMintContainer>

            <WhitelistedMintContent>
                <HeaderStyled>Whitelisted Mint</HeaderStyled>
                <IsWhitelistContainer>
                    <IsWhitelistCheckerText>今、KIWAMIが熱い！！</IsWhitelistCheckerText>
                    <IsWhitelistCheckerText>Public Mint cost is 80% of the final dutch auction cost. 0.08 ETH each, 1 per wallet.</IsWhitelistCheckerText>
                    <BodyLine style={{marginTop: "1em"}}/>
                    <IsWhitelistButtonContainer>
                        <IsWhitelistStatusText>{"Mint for Mice Whitelist "}</IsWhitelistStatusText>
                        <ReclaimButtonWrapper className='hover-social' onClick={miceWhitelistMintHandler}><IsWhitelistButtonText>{"MINT"}</IsWhitelistButtonText></ReclaimButtonWrapper>
                    </IsWhitelistButtonContainer>
                    <BodyLine style={{marginTop: "1em", marginBottom: "1em"}}/>

                    <IsWhitelistButtonContainer>
                        <IsWhitelistStatusText>{"Mint for Public Whitelist "}</IsWhitelistStatusText>
                        <ReclaimButtonWrapper className='hover-social' onClick={publicWhitelistMintHandler}><IsWhitelistButtonText>{"MINT"}</IsWhitelistButtonText></ReclaimButtonWrapper>
                    </IsWhitelistButtonContainer>
                </IsWhitelistContainer>
            </WhitelistedMintContent>

            </WhitelistedMintContainer>
            </BackgroundStyled>
        </ColStart>
    ) : (
        <ColStart className="w-100">
        <MintNavbar />
        <BackgroundStyled background={BgTeam}>
            <ColCenter className="mt-50px">
                <WhitelistedMintContent style={{marginLeft: "0%", marginBottom: "0%", marginTop: "4rem"}} >
                    <HeaderStyled>Whitelisted Mint</HeaderStyled>
                    <IsWhitelistContainer>
                        <IsWhitelistCheckerText>今、KIWAMIが熱い！！</IsWhitelistCheckerText>
                        <IsWhitelistCheckerText>Mint cost is 80% of the final dutch auction cost. 0.08 ETH each, 1 per wallet.</IsWhitelistCheckerText>
                        <BodyLine style={{marginTop: "1em"}}/>
                        <IsWhitelistButtonContainer>
                            <IsWhitelistStatusText>{"Mint for Mice Whitelist "}</IsWhitelistStatusText>
                            <ReclaimButtonWrapper className='hover-social' onClick={miceWhitelistMintHandler}><IsWhitelistButtonText>{"MINT"}</IsWhitelistButtonText></ReclaimButtonWrapper>
                        </IsWhitelistButtonContainer>
                        <BodyLine style={{marginTop: "1em", marginBottom: "1em"}}/>

                        <IsWhitelistButtonContainer>
                            <IsWhitelistStatusText>{"Mint for Public Whitelist "}</IsWhitelistStatusText>
                            <ReclaimButtonWrapper className='hover-social' onClick={publicWhitelistMintHandler}><IsWhitelistButtonText>{"MINT"}</IsWhitelistButtonText></ReclaimButtonWrapper>
                        </IsWhitelistButtonContainer>
                    </IsWhitelistContainer>
                </WhitelistedMintContent>
            </ColCenter>
        </BackgroundStyled>
        </ColStart>
    )
}

// const DebugLog = styled.p`
//     margin: 5px;
//     color: white;
//     font-size: 1rem;
// `

export default WhitelistedMint;