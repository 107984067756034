import styled from "@emotion/styled"

export const BackgroundStyled = styled.div `
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  min-height: 100vh;
  width: 100%;

  @media screen and (max-width: 1023px) {
    background-position: bottom;
  }

`