import styled from "@emotion/styled"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const FooterStyled = styled.div`
  width: calc(100% - 60px);
  height: 80px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #bf004d;
  display: flex;
  align-items: center;
  position: relative;

  .img-diamond {
    height: 25px;
  }

  .kiwami-line {
    height: 5px;
    width: 150px;
  }

  @media screen and (min-width: 2500px) {
    height: 120px;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
  }

  @media screen and (min-width: 1440px) and (max-width: 2499px) {
    height: 70px;
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    height: 60px;
    .kiwami-line {
      height: 5px;
      width: 100px;
    }
  }

  @media screen and (max-width: 1022px) {
    height: 90px;

    .kiwami-line {
      height: 3px;
      width: 50px;
    }

    .kiwami-small {
      position: absolute;
      bottom: 10px;
      right: 10px;

      .img-diamond {
        height: 13px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    height: 120px;
    padding-bottom: 10px;
  }
`

export const ButtonSocialStyled = styled.img`
  height: 2vw;
  width: auto;
  margin-left: 20px;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
  }

  @media screen and (min-width: 2500px) {
    height: 2vw;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
  }

  @media screen and (min-width: 1440px) and (max-width: 2499px) {
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    font-size: 2.5vw;
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    height: 2.2vw;
  }

  @media screen and (max-width: 1022px) {
    height: 3.5vw;
  }

  @media screen and (max-width: 500px) {
    height: 6.5vw;
  }
`

export const AnchorLinkStyled = styled(AnchorLink)`
  z-index: 2;
`

export const KiwamiSmall = styled.div`
  margin-left: 10px;
  z-index: 2;
  font-size: 1.5vw;
  font-family: "Akira", sans-serif;
  color: white;

  @media screen and (max-width: 1022px) {
    font-size: 1.3vw;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.5vw;
    margin-left: 5px;
  }
`

export const KiwamiBig = styled.div`
  z-index: 2;
  font-size: 3vw;
  font-family: "Akira", sans-serif;
  color: white;
  margin: 0 24px;
  
  @media screen and (min-width: 2500px) {
    font-size: 3vw;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
  }

  @media screen and (min-width: 1440px) and (max-width: 2499px) {
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    font-size: 2.5vw;
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    font-size: 2.5vw;
  }

  @media screen and (max-width: 1022px) {
    font-size: 2.2vw;
  }

  @media screen and (max-width: 500px) {
    font-size: 3.2vw;
  }

  @media screen and (max-width: 360px) {
    font-size: 3.2vw;
  }
`

export const SocialWrapperStyled = styled.div`
  width: 40px;
  height: 40px;
  background-color: #bf004d;
  cursor: pointer;
  text-align: center;
  line-height: 43px;
  font-size: 24px;
  margin-right: 1rem;
  margin-left: 1rem;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  
  z-index: 2;

  position: relative;
  color: #262626;
  transition: .5s;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 2;
    background: white;
  }

  &:hover {
    color: #fff;

    &:before {
      top: 0;
    }
  }
`

export const SocialIconStyled = styled(FontAwesomeIcon)`
  position: relative;
  color: black;
  z-index: 2;
`

export const OpenseaIconStyled = styled.img`
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: black;
  z-index: 2;
`
