import { useWeb3React } from '@web3-react/core';
import React, { useState, useEffect } from 'react';


import BgTeam from "../../assets/images/desktop/bg_team.jpg"
import VerifyProfile from "../../assets/images/desktop/verify.png"
import { ColStart, ColCenter } from "../../components/general_styled"
import MintNavbar from "../../layouts/MintNavbar"
import { BackgroundStyled } from '../Home/styled';
import { Profile, VerifyContainer, VerifyContent } from './styled';
// import useInterval from 'use-interval';

import { HeaderStyled, IsWhitelistContainer, IsWhitelistCheckerText, IsWhitelistStatusText, IsWhitelistButtonContainer, MintContentStyled, BodyLine } from '../Mint/styled';
// import { ContentStyled, HeaderStyled, Links, MintBox, ImageMintProfileStyled, ImageContainer, MintText, MintButtonWrapperStyled, MintButtonText, IsWhitelistContainer, IsWhitelistCheckerText, IsWhitelistButtonContainer, IsWhitelistButtonWrapper, IsWhitelistStatusText, IsWhitelistButtonText, DutchStatsContainer, DutchPriceDetails, DutchMintButtonContainer, DutchInputQuantity, DutchSubmitButtonWrapper, DutchReclaimContainer, DutchReclaimSubContainer, ReclaimStat, ReclaimButtonWrapper, DesktopImage, MintContentStyled, DesktopImageContainer, DutchReclaimPlaceholderContainer, BodyLine } from './styled';
import useWindowDimensions from "../../utils/windowDimensions";

import useKiwamiContract from '../Mint/interfaces/useKiwamiContract';


const Verify = () => {
    const { width } = useWindowDimensions()
    const { account } = useWeb3React()

    const [userAddress, setUserAddress] = useState(account ? account : '')
    const [isMouseWhitelisted, setIsMouseWhitelisted] = useState(false);
    const [isPublicWhitelisted, setIsPublicWhitelisted] = useState(false);
    // const [mouseProof, setMouseProof] = useState([]);
    // const [publicProof, setPublicProof] = useState([]);

    const { Kiwami } = useKiwamiContract();

    useEffect(() => {

        // useKiwamiContract()
        // .then((Kiwami) => {
        // // Currently need this in other to set isMouseWhitelisted/isPublicWhitelisted for wl mint button
        //     if(userAddress && userAddress !== '') {
        //         fetch('https://merkednodel.herokuapp.com/proof', {
        //         method: 'POST',
        //         credentials: 'include',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ userAddress: userAddress }),
        //         })
        //         .then((res) => res.json())
        //         .then((res) => {
        //             if(res.errormessage) {
        //                 console.log(res.errormessage)
        //                 setIsMouseWhitelisted(false)
        //                 setIsPublicWhitelisted(false)
        //                 // setPublicProof([])
        //                 // setMouseProof([])
        //             } else {
        //                 // console.log("In whitelist checker")
        //                 // console.log(res)
        //                 // setPublicProof(res.publicProof)
        //                 // setMouseProof(res.mouseProof)

        //                 Kiwami.verifyMouseSender(userAddress, res.mouseProof)
        //                 .then((isVerifiedOnChain) => {
        //                     // console.log("verify mouse: ", isVerifiedOnChain)
        //                     if(isVerifiedOnChain) {
        //                         setIsMouseWhitelisted(true)
        //                     } else {
        //                         setIsMouseWhitelisted(false)
        //                     }
        //                     console.log("Mouse Verify Resp: ", isVerifiedOnChain)
        //                 })
        //                 .catch((err) => {
        //                     console.log(`verify mouse err with inputs: ${userAddress} ${res.publicProof}`)
        //                 })

        //                 Kiwami.verifyPublicSender(userAddress, res.publicProof)
        //                 .then((isVerifiedOnChain) => {
        //                     // console.log("verify public: ", isVerifiedOnChain)
        //                     if(isVerifiedOnChain) {
        //                         setIsPublicWhitelisted(true)
        //                     } else {
        //                         setIsPublicWhitelisted(false)
        //                     }
        //                     console.log("Public Verify Resp: ", isVerifiedOnChain)

        //                 })
        //                 .catch(() => {
        //                     console.log(`verify public err with inputs: ${userAddress} ${res.publicProof}`)
        //                 })
        //             }
        //         })
        //         .catch((err) => {
        //             console.log(err)
        //         })
        //     }
        // })
        // .catch((err) => {
        //     console.log(err)
        // })


    }, [userAddress])

    if(account && account !== userAddress) {
        setUserAddress(account ? account : '')
    }


    const refresh = async () => {
        if(userAddress && userAddress !== '') {
            fetch('https://merkednodel.herokuapp.com/proof', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userAddress: userAddress }),
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.errormessage) {
                    console.log(res.errormessage)
                    setIsMouseWhitelisted(false)
                    setIsPublicWhitelisted(false)
                    // setPublicProof([])
                    // setMouseProof([])
                } else {
                    // console.log("In whitelist checker")
                    // console.log(res)
                    // setPublicProof(res.publicProof)
                    // setMouseProof(res.mouseProof)

                    Kiwami.verifyMouseSender(userAddress, res.mouseProof)
                    .then((isVerifiedOnChain) => {
                        // console.log("verify mouse: ", isVerifiedOnChain)
                        if(isVerifiedOnChain) {
                            setIsMouseWhitelisted(true)
                        } else {
                            setIsMouseWhitelisted(false)
                        }
                        console.log("Mouse Verify Resp: ", isVerifiedOnChain)

                    })
                    .catch((err) => {
                        console.log(`verify mouse err with inputs: ${userAddress} ${res.publicProof}`)
                    })

                    Kiwami.verifyPublicSender(userAddress, res.publicProof)
                    .then((isVerifiedOnChain) => {
                        // console.log("verify public: ", isVerifiedOnChain)
                        if(isVerifiedOnChain) {
                            setIsPublicWhitelisted(true)
                        } else {
                            setIsPublicWhitelisted(false)
                        }
                        console.log("Public Verify Resp: ", isVerifiedOnChain)

                    })
                    .catch(() => {
                        console.log(`verify public err with inputs: ${userAddress} ${res.publicProof}`)
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

    // async function checkIfWhitelisted() {
    //     if(userAddress && userAddress !== '') {
    //         fetch('https://merkednodel.herokuapp.com/proof', {
    //         method: 'POST',
    //         credentials: 'include',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ userAddress: userAddress }),
    //         })
    //         .then((res) => res.json())
    //         .then((res) => {
    //             if(res.errormessage) {
    //                 console.log(res.errormessage)
    //                 setIsMouseWhitelisted(false)
    //                 setIsPublicWhitelisted(false)
    //                 // setPublicProof([])
    //                 // setMouseProof([])
    //             } else {
    //                 // console.log("In whitelist checker")
    //                 // console.log(res)
    //                 // setPublicProof(res.publicProof)
    //                 // setMouseProof(res.mouseProof)

    //                 Kiwami.verifyMouseSender(userAddress, res.mouseProof)
    //                 .then((isVerifiedOnChain) => {
    //                     // console.log("verify mouse: ", isVerifiedOnChain)
    //                     if(isVerifiedOnChain) {
    //                         setIsMouseWhitelisted(true)
    //                     } else {
    //                         setIsMouseWhitelisted(false)
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     console.log(`verify mouse err with inputs: ${userAddress} ${res.publicProof}`)
    //                 })

    //                 Kiwami.verifyPublicSender(userAddress, res.publicProof)
    //                 .then((isVerifiedOnChain) => {
    //                     // console.log("verify public: ", isVerifiedOnChain)
    //                     if(isVerifiedOnChain) {
    //                         setIsPublicWhitelisted(true)
    //                     } else {
    //                         setIsPublicWhitelisted(false)
    //                     }
    //                 })
    //                 .catch(() => {
    //                     console.log(`verify public err with inputs: ${userAddress} ${res.publicProof}`)
    //                 })
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    //     }
    // }

    // checkIfWhitelisted()

    // useInterval(() => {
    //     refresh()
    // }, 5000)


    refresh()
    




    // <html style={{minHeight: "100%", position: "relative"}}>
    // <body style={{height: "100%"}}>
    // <DesktopImageContainer>
    //     <DesktopImage src={MintProfile}/>
    // </DesktopImageContainer>
    // </body>
    // </html>

    return width > 650 ? (
        <ColStart className="w-100">
            <MintNavbar />
            <BackgroundStyled background={BgTeam}>
            <Profile background={VerifyProfile} />
            <VerifyContainer>

            <VerifyContent>
                <HeaderStyled>Mintlist Verification</HeaderStyled>
                <IsWhitelistContainer>
                    <IsWhitelistCheckerText>Connect to metamask to confirm if you are on the mintlists.</IsWhitelistCheckerText>
                    <BodyLine style={{marginTop: "1em"}}/>
                    <IsWhitelistButtonContainer>
                        <IsWhitelistStatusText>{"Mice Mintlist status: "}</IsWhitelistStatusText>
                        <IsWhitelistStatusText style={{ fontSize: "1.2em", fontWeight: "800", color: (isMouseWhitelisted) ? "green" : "red" }}>{(isMouseWhitelisted) ? "You are mintlisted!" : "Not mintlisted"}</IsWhitelistStatusText>
                    </IsWhitelistButtonContainer>
                    <IsWhitelistButtonContainer>
                        <IsWhitelistStatusText>{"Public Mintlist status: "}</IsWhitelistStatusText>
                        <IsWhitelistStatusText style={{ fontSize: "1.2em", fontWeight: "800", color: (isPublicWhitelisted) ? "green" : "red" }}>{(isPublicWhitelisted) ? "You are mintlisted!" : "Not mintlisted"}</IsWhitelistStatusText>
                    </IsWhitelistButtonContainer>
                </IsWhitelistContainer>
            </VerifyContent>

            </VerifyContainer>
            </BackgroundStyled>
        </ColStart>
    ) : (
        <ColStart className="w-100">
        <MintNavbar />
        <BackgroundStyled background={BgTeam}>
            <ColCenter className="mt-50px" >
                <MintContentStyled style={{marginRight: "0px", marginTop: "4rem"}}>
                    <HeaderStyled>Mintlist Verification</HeaderStyled>
                    <IsWhitelistContainer>
                        <IsWhitelistCheckerText>Connect to metamask to confirm if you are on the mintlists.</IsWhitelistCheckerText>
                        <BodyLine style={{marginTop: "1em"}}/>
                        <IsWhitelistButtonContainer>
                            <IsWhitelistStatusText>{"Mice Mintlist status: "}</IsWhitelistStatusText>
                            <IsWhitelistStatusText style={{ fontSize: "1.2em", fontWeight: "800", color: (isMouseWhitelisted) ? "green" : "red" }}>{(isMouseWhitelisted) ? "You are mintlisted!" : "Not mintlisted"}</IsWhitelistStatusText>
                        </IsWhitelistButtonContainer>
                        <IsWhitelistButtonContainer>
                            <IsWhitelistStatusText>{"Public Mintlist status: "}</IsWhitelistStatusText>
                            <IsWhitelistStatusText style={{ fontSize: "1.2em", fontWeight: "800", color: (isPublicWhitelisted) ? "green" : "red" }}>{(isPublicWhitelisted) ? "You are mintlisted!" : "Not mintlisted"}</IsWhitelistStatusText>
                        </IsWhitelistButtonContainer>
                    </IsWhitelistContainer>
                </MintContentStyled>
            </ColCenter>
        </BackgroundStyled>
        </ColStart>
    )

}

// const DebugLog = styled.p`
//     margin: 5px;
//     color: white;
//     font-size: 1rem;
// `

export default Verify;
