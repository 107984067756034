import styled from "@emotion/styled";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";

import { getAbbreviatedAddress } from "../utils/util";
import WalletModal from "./WalletModal";

const ConnectWallet = ({ darkMode = false }) => {
  const { account } = useWeb3React();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onModalClose = () => setIsModalOpen(false);

  const textColor = darkMode ? "white" : "black";
  return (
    <>
      <Button color={textColor} onClick={() => setIsModalOpen(true)}>
        {account ? getAbbreviatedAddress(account) : "Connect wallet"}
      </Button>
      <WalletModal isOpen={isModalOpen} onClose={onModalClose} />
    </>
  );
};

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  font-family: akira;
  font-size: 28px;
  background: none;
  cursor: pointer;
  border: none;

  position: relative;
  text-align: center;
  color: ${props => props.color};
  z-index: 3;
  transition: 0.5s;
  line-height: 55px;

  padding-bottom: 0px;

  &:hover {
    color: ${props => props.color};
    font-size: 26px;
  }
`;

export default ConnectWallet;
