import styled from "@emotion/styled"

export const BackgroundStyled = styled.div`
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  min-height: 100vh;
  width: 100%;

  @media screen and (max-width: 1023px) {
    background-position: bottom;
  }
`

// export const BoxTeamStyled = styled.div`
//   // background-image: url(${(props) => props.background});
//   // background-repeat: no-repeat;
//   // background-size: contain;
//   // background-color: red;
//   // background: rgb(190, 0, 79);
//   // background: linear-gradient(
//   //   180deg,
//   //   rgba(190, 0, 79, 0.5) 0%,
//   //   rgba(255, 255, 255, 0) 100%
//   // );

//   // border: 1px solid #be004f;

//   // min-height: 40vh;
//   padding-top: 20px;
//   padding-left: 30px;
//   padding-right: 90px;

//   @media screen and (min-width: 2500px) {
//     padding-top: 30px;
//     padding-left: 50px;
//     padding-right: 50px;
//     height: 30vh;
//   }

//   @media screen and (min-width: 1920px) and (max-width: 2499px) {
//     padding-top: 20px;
//     padding-left: 30px;
//     padding-right: 50px;
//     height: 30vh;
//   }

//   @media screen and (min-width: 1440px) and (max-width: 1919px) {
//     padding-top: 20px;
//     padding-left: 30px;
//     padding-right: 40px;
//     height: 30vh;
//   }

//   @media screen and (min-width: 1366px) and (max-width: 1439px) {
//     padding-top: 10px;
//     padding-left: 30px;
//     padding-right: 30px;
//     height: 30vh;
//   }

//   @media screen and (min-width: 1025px) and (max-width: 1365px) {
//     padding-top: 10px;
//     padding-left: 30px;
//     padding-right: 30px;
//     height: 30vh;
//   }



//   // @media screen and (min-width: 1030px) and (min-height: 1160px) {
//   //   background-image: url(${(props) => props.background});
//   //   background-repeat: no-repeat;
//   //   background-size: contain;
//   // }

//   @media screen and (max-width: 1022px) {
//     width: calc(100% - 140px);
//     padding-top: 10px;
//     padding-right: 20px;
//     padding-left: 20px;
//     margin-right: 50px;
//     margin-left: 50px;
//     height: 30vh;
//   }

//   @media screen and (max-width: 768px) {
//     width: calc(100% - 100px);
//     padding-top: 5px;
//     padding-right: 20px;
//     padding-left: 20px;
//     margin-right: 30px;
//     margin-left: 30px;
//     height: 25vh;
//     min-height: 0px;
//   }

//   @media screen and (max-width: 425px) {
//     width: calc(100% - 60px);
//     padding-top: 5px;
//     padding-right: 20px;
//     padding-left: 20px;
//     margin-right: 15px;
//     margin-left: 15px;
//     height: 31vh;
//     min-height: 0px;
//   }

//   @media screen and (max-width: 375px) {
//     width: calc(100% - 60px);
//     padding-top: 5px;
//     padding-right: 20px;
//     padding-left: 20px;
//     margin-right: 15px;
//     margin-left: 15px;
//     height: 40vh;
//     min-height: 0px;
//   }

//   @media screen and (max-width: 340px) {
//     width: calc(100% - 40px);
//     padding-top: 5px;
//     padding-right: 10px;
//     padding-left: 10px;
//     margin-right: 10px;
//     margin-left: 10px;
//     height: 50vh;
//     min-height: 0px;
//   }
// `

export const ImageTeamStyled = styled.img`
  width: 35%;
  height: auto;
  margin-right: 5%;

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    width: 30%;
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    width: 30%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    width: 30%;
  }

  @media screen and (max-width: 1023px) {
    width: 60%;
    margin-right: 0;
    margin-top: 2%;
  }

  @media screen and (min-width: 500px) and (max-width: 1023px) {
    // width:
  }

`

export const DescTeamStyled = styled.div`
  font-size: 18px;
  font-family: Montserrat;
  color: white;
  letter-spacing: 0.5px;
  margin-top: 10px;
  line-height: 1.5;

  min-height: 8rem;

  @media screen and (min-width: 2500px) {
    font-size: 30px;
    // min-height: 10px;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    font-size: 24px;
    // min-height: 10px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    font-size: 18px;
    // min-height: 10px;
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    font-size: 17px;
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1023px) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`
